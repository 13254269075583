.service-detail .service-image {
  position: relative;
}
.service-detail .service-image .section-icon {
  width: 128px;
  height: 128px;
  position: absolute;
  top: 0;
  right: 0;
  background: #FF6943;
  color: #fff;
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-page-hero .service-list {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #DCE1E6;
  overflow: hidden;
}
.services-page-hero .service-list .service-item {
  width: 20%;
}
.services-page-hero .service-list .service-item a {
  border-left: 1px solid #DCE1E6;
  border-top: 1px solid #DCE1E6;
  margin-left: -1px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;
  text-align: center;
}
.services-page-hero .service-list .service-item a:hover {
  background: #f4f4f4;
  box-shadow: inset 0 0 0 12px #FFFFFF;
}
.services-page-hero .service-list .service-item a:hover h2 {
  color: #000;
}
.services-page-hero .service-list .service-item a h2 {
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 12px;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.services-page-hero .service-list .service-item a .service-icon {
  color: #FF6943;
  border-radius: 100%;
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
}
