@import "https://use.typekit.net/itv0idl.css";
.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1em solid rgba(0, 0, 0, 0.08);
  border-left: 1em solid #FF6943;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 8px 0;
  font-weight: 700;
  line-height: normal;
  color: #000;
}
h1,
.h1 {
  font-size: 56px;
  line-height: calc(56px + 10px);
}
h2,
.h2 {
  font-size: 28px;
  line-height: calc(28px + 10px);
}
h3,
.h3 {
  font-size: 26px;
  line-height: calc(26px + 10px);
}
h4,
.h4 {
  font-size: 20px;
  line-height: calc(20px + 10px);
}
h5,
.h5 {
  font-size: 18px;
  line-height: calc(18px + 10px);
}
h6,
.h6 {
  font-size: 14px;
  line-height: calc(14px + 10px);
  text-transform: uppercase;
  margin-bottom: 0;
}
p {
  line-height: 24px;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: calc(32px + 8px);
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: calc(24px + 8px);
  }
  h3,
  .h3 {
    font-size: 20px;
    line-height: calc(20px + 8px);
  }
  h4,
  .h4 {
    font-size: 18px;
    line-height: calc(18px + 8px);
  }
}
::selection {
  background: #000;
  color: #fff;
}
a {
  transition: all 120ms ease-in-out;
  text-decoration: none;
}
a.simple-link {
  text-decoration: underline;
}
a.simple-link:hover {
  opacity: 0.87;
  text-decoration: none;
}
.no-scroll {
  overflow: hidden;
}
.project-card {
  position: relative;
  width: 100%;
  background: #000;
  padding-top: 120%;
  overflow: hidden;
  color: #fff;
  transition: all 240ms ease-in-out;
  margin-bottom: 64px;
}
.project-card .project-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 240ms ease-in-out;
}
.project-card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.project-card .project-details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  padding: 32px;
  width: calc(100% - 56px);
}
.project-card .project-details .project-tagline {
  opacity: 0.64;
  margin-bottom: 4px;
  display: block;
}
.project-card .project-details .project-name {
  font-size: 22px;
  line-height: normal;
}
.project-card .button-link {
  position: absolute;
  z-index: 4;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  transition: all 240ms ease-in-out;
}
.project-card:hover .project-image {
  transform: scale(1.03);
  opacity: 0.54;
}
.project-card:hover .button-link {
  color: #FF6943;
  background: #fff;
}
.button-link {
  transition: all 120ms ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 8px;
  background: #FF6943;
  color: #fff;
  min-height: 32px;
  min-width: 32px;
  font-size: 18px;
  font-weight: 700;
}
.button-link:hover {
  background: #D45332;
}
.button-link:active {
  background: #B1462A;
}
.primary-color {
  color: #FF6943;
}
.typist-wrapper {
  display: inline-flex;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
section {
  width: 100%;
}
img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
strong {
  font-weight: 700;
}
.color-on-dark {
  color: #fff;
}
.normal-weight {
  font-weight: normal;
}
.light-weight {
  font-weight: 300;
}
.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.default-inline-link {
  color: #000;
  font-weight: 600;
  padding: 4px 0;
  border-bottom: 3px solid #FF6943;
}
.default-inline-link:hover {
  border-color: #000;
}
.flexbox {
  display: flex;
  align-items: center;
}
.flexbox.space-between {
  justify-content: space-between;
}
.flexbox.top-align {
  align-items: flex-start;
}
.flexbox.bottom-align {
  align-items: flex-end;
}
.flexbox.flex-wrap {
  flex-wrap: wrap;
}
.flexbox .center-content {
  justify-content: center;
}
.flexbox.flex-column {
  flex-direction: column;
}
.gray-background {
  background: #f4f4f4;
}
.gray-background .main-section {
  background: #f4f4f4 !important;
}
.white-background {
  background: #fff;
}
.white-background .main-section {
  background: #fff !important;
}
.main-section .section-content .section-copy,
.main-section .section-content .section-side {
  width: calc(50% - 32px);
}
.main-section .section-content .section-copy.wide-content,
.main-section .section-content .section-side.wide-content {
  width: 70%;
}
.main-section a.main-link {
  font-size: 18px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 3px solid #FF6943;
  display: inline-flex;
  line-height: normal;
  margin-top: 24px;
  font-weight: 700;
  position: relative;
}
.main-section a.main-link .icon {
  position: absolute;
  color: #000;
  right: -8px;
  transition: all 120ms ease-in-out;
  opacity: 0;
}
.main-section a.main-link:hover {
  color: #000;
  border-color: #000;
  padding-right: 20px;
}
.main-section a.main-link:hover .icon {
  opacity: 1;
}
.larger-paragraph {
  font-size: 18px;
  line-height: 28px;
}
.block-content {
  display: block;
}
.has-top-border {
  border-top: 1px solid #DCE1E6;
}
.has-bottom-border {
  border-bottom: 1px solid #DCE1E6;
}
.has-left-border {
  border-left: 1px solid #DCE1E6;
}
.has-right-border {
  border-right: 1px solid #DCE1E6;
}
.no-border {
  border: none;
}
.default-padding {
  padding: 164px 0;
}
.small-padding {
  padding: 64px 0;
}
.no-interaction {
  pointer-events: none;
}
.default-margin {
  margin-top: 24px;
  margin-bottom: 24px;
}
.no-margin {
  margin: 0;
}
.mg-b-8 {
  margin-bottom: 8px;
}
.mg-b-16 {
  margin-bottom: 16px;
}
.mg-t-8 {
  margin-top: 8px;
}
.mg-t-16 {
  margin-top: 16px;
}
.default-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
hr {
  border: none;
  height: 1px;
  background: #DCE1E6;
  margin: 48px 0;
}
hr.short-separator {
  width: 50px;
  height: 4px;
}
hr.mg-24 {
  margin: 24px 0;
}
.spacer-8 {
  height: 8px;
}
.spacer-16 {
  height: 16px;
}
.spacer-24 {
  height: 24px;
}
.spacer-32 {
  height: 32px;
}
.spacer-48 {
  height: 48px;
}
.spacer-64 {
  height: 64px;
}
.spacer-128 {
  height: 128;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inline-hr {
  width: 24px;
  height: 1px;
  background: #FF6943;
  margin-right: 8px;
}
.all-caps {
  text-transform: uppercase;
  letter-spacing: 0.2px;
}
.small-text {
  font-size: 14px;
  line-height: normal;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.64);
  font-family: trade-gothic-next, 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-display: auto;
}
.app-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;
}
.app-wrapper.privacy-tos .content-copy ul,
.app-wrapper.privacy-tos .content-copy li {
  line-height: 24px;
}
.app-wrapper.privacy-tos .content-copy p {
  margin-top: 0;
  margin-bottom: 16px;
}
.app-wrapper.privacy-tos .content-copy a {
  text-decoration: underline;
  color: #000;
}
.app-wrapper.privacy-tos .content-copy strong {
  color: #000;
}
.app-wrapper.privacy-tos .content-copy hr {
  margin: 24px 0;
}
img,
iframe {
  max-width: 100%;
  width: 100%;
}
.breadcrumb ol {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.breadcrumb ol li a {
  display: flex;
  align-items: center;
  color: #000;
  opacity: 0.48;
}
.breadcrumb ol li a:hover {
  opacity: 0.72;
}
.breadcrumb ol li a .icon {
  margin-left: -4px;
}
.breadcrumb ol li.selected {
  color: #fff;
  cursor: default;
  pointer-events: none;
}
.mobile-nav-drawer * {
  outline: none;
}
.mobile-nav-drawer .header {
  height: 72px;
  font-size: 18px;
  padding: 0 24px;
  display: flex !important;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #DCE1E6;
}
.mobile-nav-drawer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mobile-nav-drawer ul li {
  display: block;
  position: relative;
}
.mobile-nav-drawer ul li a {
  display: block;
  padding: 24px;
  font-size: 18px;
  line-height: normal;
  color: #000;
}
.mobile-nav-drawer ul li a:hover {
  background: #f4f4f4;
}
.mobile-nav-drawer ul li.selected {
  font-weight: 600;
}
.mobile-nav-drawer ul li.selected a {
  color: #FF6943;
  background: #f4f4f4;
}
.mobile-nav-drawer ul li.selected a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background: #FF6943;
}
.bm-cross-button {
  width: 40px !important;
  height: 40px !important;
  top: 14px !important;
  right: 8px !important;
}
.bm-cross-button .bm-cross {
  color: #000;
  font-size: 24px;
  opacity: 0.54;
}
.bm-cross-button svg {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out 0s !important;
  -webkit-transition: all 0.3s ease-in-out 0s !important;
}
.bm-menu {
  background: #fff;
}
.bm-morph-shape {
  fill: #fff;
}
.bm-item-list {
  background: #fff;
}
.bm-burger-button {
  display: none;
  color: #fff !important;
  fill: #fff !important;
}
.bm-burger-button svg {
  fill: #fff !important;
}
.app-header {
  flex: 0 0 auto;
  padding: 0 24px;
  height: 92px;
  border-bottom: 1px solid #DCE1E6;
}
.app-header .mobile-navigation {
  display: none;
  position: relative;
}
.app-header .mobile-navigation .mobile-nav-links {
  position: absolute;
}
.app-header .mobile-navigation .navigation-trigger {
  height: 72px;
  padding-bottom: 4px;
  cursor: pointer;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
  color: #000;
  font-size: 18px;
}
.app-header .mobile-navigation .navigation-trigger .icon {
  margin-right: 8px;
  opacity: 0.64;
  font-size: 24px;
}
.app-header .mobile-navigation .navigation-trigger strong {
  margin-top: -2px;
  line-height: normal;
  font-weight: normal;
}
.app-header .mobile-navigation .navigation-trigger:active {
  opacity: 0.54;
}
.app-header .logo {
  height: 48px;
  width: 129px;
  background: url(../assets/images/logo.svg) no-repeat center left;
  background-size: 100%;
  display: inline-flex;
}
.app-header .desktop-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 18px;
}
.app-header .desktop-navigation li {
  margin-left: 40px;
  margin-top: -8px;
}
.app-header .desktop-navigation li:first-of-type {
  margin-left: 0;
}
.app-header .desktop-navigation li a {
  color: rgba(0, 0, 0, 0.54);
  padding: 12px 0;
  position: relative;
}
.app-header .desktop-navigation li a:hover {
  color: #000;
}
.app-header .desktop-navigation li.selected a {
  color: #FF6943;
}
.app-header .desktop-navigation li.selected a:after {
  content: '';
  height: 4px;
  width: 100%;
  background: #FF6943;
  position: absolute;
  bottom: -27px;
  left: 0;
  right: 0;
}
.app-header.transparent {
  background: transparent;
  position: relative;
  z-index: 2;
  border-color: transparent;
}
.app-header.transparent .logo {
  background: url(../assets/images/logo-negative.svg) no-repeat center left;
  background-size: 100%;
}
.app-header.transparent .desktop-navigation li a {
  color: rgba(255, 255, 255, 0.54);
  padding: 12px 0;
  border-bottom: 4px solid transparent;
}
.app-header.transparent .desktop-navigation li a:hover {
  color: #fff;
}
.app-header.transparent .desktop-navigation li.selected a {
  color: #FF6943;
  border-color: #FF6943;
  cursor: default;
}
.app-header.transparent .mobile-navigation .navigation-trigger {
  color: #fff;
}
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999 !important;
  -webkit-transition: background 200ms ease-in-out;
  transition: background 200ms ease-in-out;
}
.headroom-wrapper {
  height: 92px !important;
}
.headroom-wrapper.has-big-hero .headroom--pinned {
  border-bottom: 1px solid #DCE1E6;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .logo {
  height: 48px;
  width: 129px;
  background: url(../assets/images/logo.svg) no-repeat center left;
  background-size: 100%;
  display: inline-flex;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li a {
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 4px solid transparent;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li a:hover {
  color: #000;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li.selected a {
  color: #FF6943;
  border-color: #FF6943;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .mobile-navigation .navigation-trigger {
  color: #000;
}
.headroom--unfixed {
  position: relative;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.headroom--scrolled {
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  border-bottom: 1px solid #DCE1E6;
}
.headroom--pinned {
  position: fixed;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  background: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
}
.app-content {
  flex: 1 1;
  -webkit-animation: fade-in 500ms ease-in-out;
  animation: fade-in 500ms ease-in-out;
  min-height: 0;
}
.content-wrapper {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 3;
}
@media (max-height: 940px) {
  .default-padding {
    padding: 100px 0;
  }
  .app-content.has-big-hero .big-hero {
    min-height: 100vh;
  }
}
@media (min-width: 1104px) {
  .app-header {
    padding: 0 40px;
  }
}
@media (max-width: 1104px) {
  .default-padding {
    padding: 56px 0;
  }
  .main-section .section-content {
    flex-wrap: wrap;
  }
  .main-section .section-content .section-copy,
  .main-section .section-content .section-side {
    width: calc(50% - 16px);
  }
}
@media (max-width: 800px) {
  .default-padding,
  .small-padding {
    padding: 48px 0;
  }
  hr {
    margin: 24px 0;
  }
  .app-content.has-big-hero .big-hero {
    min-height: unset;
    margin-top: -72px;
    padding: 128px 0 64px 0;
  }
  .app-content.has-big-hero .big-hero h1 {
    font-size: 56px;
    line-height: 66px;
    max-width: unset;
  }
  .app-content.has-big-hero .big-hero a.button-link {
    width: 64px;
    height: 64px;
    margin-top: 48px;
  }
  .main-section .section-content {
    flex-wrap: wrap;
  }
  .main-section .section-content .section-copy {
    width: 100%;
  }
  .main-section .section-content .section-copy.wide-content {
    width: 100%;
  }
  .main-section .section-content .section-side {
    width: 100%;
  }
  .main-section a.main-link {
    margin-top: 16px;
    padding-right: 20px;
  }
  .main-section a.main-link .icon {
    opacity: 1;
    top: 2px;
  }
  .main-section.who-we-are .team-image {
    margin-top: 16px;
  }
  .main-section.work-samples .section-content {
    flex-wrap: wrap-reverse;
  }
  .main-section.what-we-do .services-grid {
    border: 1px solid #DCE1E6;
    border-bottom: none;
    border-left: none;
    border-radius: 4px;
    overflow: hidden;
  }
  .main-section.what-we-do .services-grid li {
    border-left: 1px solid #DCE1E6;
    border-bottom: 1px solid #DCE1E6;
  }
  .main-section.about-page-hero .about-key-points .key-item {
    width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(3),
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(4) {
    margin-bottom: 0;
  }
  .main-section.about-page-team .team-listing .team-member {
    width: calc(50% - 12px);
    margin-top: 24px;
  }
  .main-section.services-page-process .process-listing {
    padding-top: 24px;
    margin-top: 24px;
  }
  .main-section.services-page-process .process-listing .process-item {
    width: calc(50% - 12px);
    margin-bottom: 32px;
  }
  .main-section.services-page-process .process-listing .process-item:last-of-type {
    margin-bottom: 0;
  }
  .main-section.services-page-hero .service-list {
    margin-top: 16px;
    justify-content: flex-start;
    border-bottom: none;
    border-right: none;
  }
  .main-section.services-page-hero .service-list .service-item {
    width: calc(100% / 2);
  }
  .main-section.services-page-hero .service-list .service-item a {
    padding: 24px 16px;
    border: 1px solid #DCE1E6;
  }
  .main-section.services-page-hero .service-list .service-item a .service-icon {
    font-size: 48px;
    margin: 0;
  }
  .main-section.services-page-hero .service-list .service-item a h2 {
    margin-bottom: 0;
  }
  .main-section.services-page-hero .service-list .service-item a:hover {
    box-shadow: none;
  }
  .main-section.service-detail .section-content {
    flex-wrap: wrap-reverse;
  }
  .main-section.service-detail .service-image {
    margin-bottom: 24px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -48px;
  }
  .main-section.service-detail .service-image .section-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    bottom: 0;
    top: auto;
    right: auto;
    left: 24px;
  }
  .main-section.tech-stack-section .tech-stack-cloud {
    margin-top: 16px;
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    border-radius: 16px;
  }
  .main-section.work-page-hero .projects-showcase {
    margin-top: 16px;
  }
  .main-section.project-page-details .project-navigation .nav-link {
    width: 50%;
    padding: 16px 12px;
  }
  .main-section.project-page-details .project-navigation .nav-link:first-of-type {
    border-right: none;
  }
  .main-section.project-page-details .project-navigation .nav-link .aligner {
    width: calc(100% - 40px);
  }
  .main-section.project-page-details .project-navigation .nav-link .project-name,
  .main-section.project-page-details .project-navigation .nav-link strong {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
  .main-section.project-page-details .section-content .section-copy.wide-content {
    width: 100%;
    padding: 0;
  }
  .main-section.project-page-details .section-content .section-side.project-info {
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
    border: 1px solid #DCE1E6;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item {
    border-bottom: 1px solid #DCE1E6;
    padding-bottom: 16px;
    width: 100%;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item strong {
    margin-bottom: 2px;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .main-section.project-page-details .spacer-64 {
    height: 24px;
  }
  .main-section.project-page-details .project-image {
    margin-bottom: 24px;
  }
  .main-section.project-page-details .project-image:last-of-type {
    margin-bottom: 0;
  }
  .main-section.career-page-hero .career-item {
    padding-top: 16px;
    margin-top: 16px;
    flex-wrap: wrap-reverse;
  }
  .main-section.career-page-hero .career-item .career-description,
  .main-section.career-page-hero .career-item .career-meta {
    width: 100%;
    padding: 0;
    border: none;
  }
  .main-section.career-page-hero .career-item .career-meta {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #DCE1E6;
    margin-bottom: 16px;
  }
  .main-section.career-page-hero .career-item .career-meta .meta-item {
    width: 48%;
  }
  .main-section.contact-page-hero .spacer-48 {
    height: 24px;
  }
  .main-section.contact-page-hero .section-copy {
    margin-bottom: 24px;
  }
  .main-section.contact-page-hero .section-copy:last-of-type {
    margin-bottom: 0;
  }
  .main-section.contact-page-hero .section-copy p {
    margin-top: 0;
  }
  .project-card {
    margin-bottom: 24px;
  }
  .app-header {
    padding-top: 0;
    height: 72px;
  }
  .app-header .logo {
    height: 40px;
    width: 108px;
  }
  .app-header .desktop-navigation {
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }
  .app-header .desktop-navigation li {
    margin-left: 24px;
  }
  .app-header .desktop-navigation li a {
    padding: 16px 0;
  }
  .app-header .desktop-navigation li a:after {
    bottom: -15px;
  }
  .app-header .desktop-navigation li.selected a:after {
    bottom: -15px;
  }
  .app-footer .general-info {
    margin: 48px 0;
  }
  .app-footer .general-info .item {
    margin-right: 48px;
  }
  .app-footer .social-copy {
    flex-wrap: wrap;
  }
  .app-footer .social-copy .social-links {
    width: 100%;
  }
  .app-footer .social-copy .copyright {
    margin-top: 24px;
  }
  .headroom-wrapper {
    height: 72px !important;
  }
  .headroom-wrapper.has-big-hero .headroom--pinned .app-header .logo {
    height: 40px;
    width: 108px;
  }
}
@media (max-width: 548px) {
  .app-header {
    padding-top: 0;
    height: 72px;
  }
  .app-header .logo {
    height: 40px;
    width: 108px;
  }
  .app-header .desktop-navigation {
    display: none;
  }
  .app-header .mobile-navigation {
    display: block;
  }
}
@media (max-width: 500px) {
  .main-section.what-we-do .services-grid li {
    width: 100%;
  }
  .main-section.about-page-hero .about-key-points .key-item {
    width: 100%;
    margin-bottom: 24px;
  }
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(3) {
    margin-bottom: 24px;
  }
  .main-section.about-page-team .team-listing .team-member {
    width: 100%;
  }
  .main-section.services-page-process .process-listing .process-item {
    width: 100%;
    margin-bottom: 24px;
  }
  .main-section.services-page-process .process-listing .process-item:last-of-type {
    margin-bottom: 0;
  }
  .main-section.project-page-details .project-navigation {
    flex-wrap: wrap;
  }
  .main-section.project-page-details .project-navigation .nav-link {
    width: 100%;
  }
  .main-section.project-page-details .project-navigation .nav-link:first-of-type {
    border-right: 1px solid #DCE1E6;
    margin-bottom: 8px;
  }
  .main-section.project-page-details .project-navigation .nav-link .aligner {
    width: calc(100% - 40px);
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item {
    width: calc(100% / 3);
    padding: 16px 8px;
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    border-radius: 16px;
  }
  .app-footer .general-info {
    margin: 48px 0 0 0;
    flex-wrap: wrap;
  }
  .app-footer .general-info .item {
    margin-right: 0;
    margin-bottom: 48px;
    width: 100%;
  }
  .app-content.has-big-hero .big-hero h1 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 400;
  }
  .app-content.has-big-hero .big-hero a.button-link {
    margin-top: 24px;
  }
}
