.main-section.what-we-do .services-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}
.main-section.what-we-do .services-grid li {
  width: 50%;
}
.main-section.what-we-do .services-grid li .list-link {
  display: flex;
  align-items: center;
  padding: 16px 6px 16px 12px;
  color: rgba(0, 0, 0, 0.64);
  border: 1px solid transparent;
}
.main-section.what-we-do .services-grid li .list-link .more-icon {
  margin-left: auto;
  opacity: 0;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.what-we-do .services-grid li .list-link:hover {
  background: #f9f9f9;
}
.main-section.what-we-do .services-grid li .list-link:hover .more-icon {
  opacity: 0.54;
}
.main-section.what-we-do .services-grid li .list-label {
  line-height: normal;
  margin-top: -2px;
}
.main-section.what-we-do .services-grid li .icon {
  color: #FF6943;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
