.career-page-hero .career-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 48px;
  margin-top: 48px;
  border-top: 1px solid #DCE1E6;
}
.career-page-hero .career-item .career-meta {
  width: 200px;
}
.career-page-hero .career-item .career-meta .meta-item {
  margin-bottom: 24px;
  line-height: normal;
}
.career-page-hero .career-item .career-meta .meta-item strong {
  display: block;
  color: #000;
}
.career-page-hero .career-item .career-description {
  width: calc(100% - 248px);
  border-right: 1px solid #DCE1E6;
  padding-right: 48px;
}
.career-page-hero .career-item .career-description h2 {
  margin-bottom: 24px;
}
.career-page-hero .career-item .career-description ul {
  line-height: 24px;
  margin: 0 0 32px 0;
  padding: 0 0 0 18px;
  list-style-position: outside;
}
