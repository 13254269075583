.tech-stack-section .tech-stack-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 48px;
  border-radius: 4px;
  border: 1px solid #DCE1E6;
  overflow: hidden;
}
.tech-stack-section .tech-stack-cloud .tech-item {
  width: 25%;
  text-align: center;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 8px;
  border-left: 1px solid #DCE1E6;
  border-bottom: 1px solid #DCE1E6;
  margin-left: -1px;
  margin-bottom: -1px;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-label {
  font-size: 14px;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-label strong {
  font-weight: normal;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
  color: #fff;
  width: 92px;
  height: 92px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-size: 48px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
.tech-stack-section .tech-stack-cloud .tech-item.react-js .tech-icon {
  background: #53C1DE;
}
.tech-stack-section .tech-stack-cloud .tech-item.react-native .tech-icon {
  background: #212121;
}
.tech-stack-section .tech-stack-cloud .tech-item.css3 .tech-icon {
  background: #2062AF;
}
.tech-stack-section .tech-stack-cloud .tech-item.html5 .tech-icon {
  background: #E44D26;
}
.tech-stack-section .tech-stack-cloud .tech-item.aws .tech-icon {
  background: #F79C0C;
}
.tech-stack-section .tech-stack-cloud .tech-item.web-components .tech-icon {
  background: #F16529;
}
.tech-stack-section .tech-stack-cloud .tech-item.python .tech-icon {
  background: #3671A2;
}
.tech-stack-section .tech-stack-cloud .tech-item.redux .tech-icon {
  background: #764ABC;
}
.tech-stack-section .tech-stack-cloud .tech-item.node-js .tech-icon {
  background: #63B547;
}
.tech-stack-section .tech-stack-cloud .tech-item.lit .tech-icon {
  background: #647BFE;
}
.tech-stack-section .tech-stack-cloud .tech-item.es6 .tech-icon {
  background: #F26522;
}
.tech-stack-section .tech-stack-cloud .tech-item.js .tech-icon {
  background: #555267;
}
