.services-page-process .process-listing {
  margin-top: 48px;
  padding-top: 48px;
  border-top: 1px solid #DCE1E6;
  position: relative;
  flex-wrap: wrap;
}
.services-page-process .process-listing .process-item {
  margin-bottom: 48px;
  width: calc(50% - 32px);
}
.services-page-process .process-listing .process-item .process-meta {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #000;
}
.services-page-process .process-listing .process-item .process-meta .process-number {
  color: #FF6943;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 8px;
  border: 2px solid #FF6943;
  border-radius: 100%;
}
.services-page-process .process-listing .process-item .process-description {
  font-size: 18px;
  line-height: 28px;
}
