@import url(https://use.typekit.net/itv0idl.css);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: 'eggnita';
  src: url(/static/media/eggnita.c1596e5c.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
[class^="custom-icon-"],
[class*=" custom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'eggnita' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-icon-adapt:before {
  content: "\E91B";
}
.custom-icon-goal:before {
  content: "\E91C";
}
.custom-icon-time:before {
  content: "\E91D";
}
.custom-icon-values:before {
  content: "\E91E";
}
.custom-icon-back-end:before {
  content: "\E900";
}
.custom-icon-behance:before {
  content: "\E901";
}
.custom-icon-cloud-architecture:before {
  content: "\E902";
}
.custom-icon-consultancy:before {
  content: "\E903";
}
.custom-icon-copywriting:before {
  content: "\E904";
}
.custom-icon-dribbble:before {
  content: "\E905";
}
.custom-icon-facebook:before {
  content: "\E906";
}
.custom-icon-linked-in:before {
  content: "\E907";
}
.custom-icon-medium:before {
  content: "\E908";
}
.custom-icon-mobile-apps:before {
  content: "\E909";
}
.custom-icon-seo:before {
  content: "\E90A";
}
.custom-icon-social-media:before {
  content: "\E90B";
}
.custom-icon-twitter:before {
  content: "\E90C";
}
.custom-icon-ui-ux:before {
  content: "\E90D";
}
.custom-icon-web-apps:before {
  content: "\E90E";
}
.custom-icon-web-services:before {
  content: "\E90F";
}
.custom-icon-aws:before {
  content: "\E910";
}
.custom-icon-css:before {
  content: "\E911";
}
.custom-icon-es6:before {
  content: "\E912";
}
.custom-icon-html:before {
  content: "\E913";
}
.custom-icon-js:before {
  content: "\E914";
}
.custom-icon-lit:before {
  content: "\E915";
}
.custom-icon-node:before {
  content: "\E916";
}
.custom-icon-python:before {
  content: "\E917";
}
.custom-icon-react:before {
  content: "\E918";
}
.custom-icon-redux:before {
  content: "\E919";
}
.custom-icon-web-components:before {
  content: "\E91A";
}

.app-footer {
  flex: 0 0 auto;
  color: #fff;
  background: #111;
}
.app-footer h1,
.app-footer h5 {
  color: #fff;
}
.app-footer .general-info {
  margin: 64px 0 92px 0;
  display: flex;
  align-items: center;
}
.app-footer .general-info .item {
  margin-right: 100px;
}
.app-footer .general-info .item:last-of-type {
  margin-right: 0;
}
.app-footer .general-info a {
  color: #fff;
  padding-bottom: 8px;
  border-bottom: 2px solid #fff;
  opacity: 0.54;
}
.app-footer .general-info a:hover {
  opacity: 0.87;
}
.app-footer .social-copy .social-links a {
  color: #fff;
  display: inline-flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 32px;
  line-height: 32px;
}
.app-footer .social-copy .social-links a:first-of-type {
  margin-left: -12px;
}
.app-footer .social-copy .social-links a .icon {
  font-size: 24px;
}
.app-footer .social-copy .social-links a:last-of-type {
  margin-right: 0;
}
.app-footer .social-copy .social-links a:hover {
  opacity: 0.54;
}
.app-footer .social-copy .copyright {
  font-size: 14px;
  line-height: normal;
  opacity: 0.48;
}
.app-footer .social-copy .copyright a {
  color: #fff;
  margin-right: 24px;
}

.app-content.has-big-hero .big-hero {
  position: relative;
  margin-top: -92px;
  z-index: 1;
  background-color: #000;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
}
.app-content.has-big-hero .big-hero:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: url(/static/media/hero-image.ea982eaa.jpg) no-repeat center;
  background-size: cover;
  opacity: 0.16;
}
.app-content.has-big-hero .big-hero h1 {
  font-size: 72px;
  line-height: 82px;
  font-weight: 300;
  color: #fff;
  max-width: 80%;
  margin: 0;
}
.app-content.has-big-hero .big-hero a.button-link {
  width: 86px;
  height: 86px;
  margin-top: 64px;
}
.app-content.has-big-hero .big-hero a.button-link .icon {
  font-size: 32px;
}

.main-section.who-we-are .team-image {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;
  overflow: hidden;
}
.main-section.who-we-are .team-image .media-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-section.what-we-do .services-grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 16px;
}
.main-section.what-we-do .services-grid li {
  width: 50%;
}
.main-section.what-we-do .services-grid li .list-link {
  display: flex;
  align-items: center;
  padding: 16px 6px 16px 12px;
  color: rgba(0, 0, 0, 0.64);
  border: 1px solid transparent;
}
.main-section.what-we-do .services-grid li .list-link .more-icon {
  margin-left: auto;
  opacity: 0;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.what-we-do .services-grid li .list-link:hover {
  background: #f9f9f9;
}
.main-section.what-we-do .services-grid li .list-link:hover .more-icon {
  opacity: 0.54;
}
.main-section.what-we-do .services-grid li .list-label {
  line-height: normal;
  margin-top: -2px;
}
.main-section.what-we-do .services-grid li .icon {
  color: #FF6943;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}


.about-page-hero .about-key-points {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-page-hero .about-key-points .key-item {
  width: calc(50% - 32px);
  margin-bottom: 56px;
}
.about-page-hero .about-key-points .key-item .key-icon {
  font-size: 64px;
  color: #FF6943;
  margin-bottom: 16px;
}
.about-page-hero .about-key-points .key-item .key-name {
  font-size: 24px;
  color: #000;
}
.about-page-team .team-listing {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-page-team .team-listing .team-member {
  margin-top: 48px;
  width: calc(50% - 32px);
}
.about-page-team .team-listing .team-member img {
  margin-bottom: 8px;
}
.about-page-team .team-listing .team-member h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: normal;
}
.about-page-team .team-listing .team-member .image-wrapper {
  position: relative;
}
.about-page-team .team-listing .team-member .image-wrapper .external-url {
  position: absolute;
  bottom: 30px;
  right: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF6943;
  color: #fff;
  font-size: 20px;
}
.about-page-team .team-listing .team-member .image-wrapper .external-url:hover {
  background: #000;
}

.services-page-process .process-listing {
  margin-top: 48px;
  padding-top: 48px;
  border-top: 1px solid #DCE1E6;
  position: relative;
  flex-wrap: wrap;
}
.services-page-process .process-listing .process-item {
  margin-bottom: 48px;
  width: calc(50% - 32px);
}
.services-page-process .process-listing .process-item .process-meta {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #000;
}
.services-page-process .process-listing .process-item .process-meta .process-number {
  color: #FF6943;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 8px;
  border: 2px solid #FF6943;
  border-radius: 100%;
}
.services-page-process .process-listing .process-item .process-description {
  font-size: 18px;
  line-height: 28px;
}

.service-detail .service-image {
  position: relative;
}
.service-detail .service-image .section-icon {
  width: 128px;
  height: 128px;
  position: absolute;
  top: 0;
  right: 0;
  background: #FF6943;
  color: #fff;
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.services-page-hero .service-list {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid #DCE1E6;
  overflow: hidden;
}
.services-page-hero .service-list .service-item {
  width: 20%;
}
.services-page-hero .service-list .service-item a {
  border-left: 1px solid #DCE1E6;
  border-top: 1px solid #DCE1E6;
  margin-left: -1px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 48px 16px;
  text-align: center;
}
.services-page-hero .service-list .service-item a:hover {
  background: #f4f4f4;
  box-shadow: inset 0 0 0 12px #FFFFFF;
}
.services-page-hero .service-list .service-item a:hover h2 {
  color: #000;
}
.services-page-hero .service-list .service-item a h2 {
  font-weight: normal;
  font-size: 18px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.64);
  margin-top: 12px;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.services-page-hero .service-list .service-item a .service-icon {
  color: #FF6943;
  border-radius: 100%;
  font-size: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px;
}

.tech-stack-section .tech-stack-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 48px;
  border-radius: 4px;
  border: 1px solid #DCE1E6;
  overflow: hidden;
}
.tech-stack-section .tech-stack-cloud .tech-item {
  width: 25%;
  text-align: center;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 32px 8px;
  border-left: 1px solid #DCE1E6;
  border-bottom: 1px solid #DCE1E6;
  margin-left: -1px;
  margin-bottom: -1px;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-label {
  font-size: 14px;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-label strong {
  font-weight: normal;
}
.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
  color: #fff;
  width: 92px;
  height: 92px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-size: 48px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
.tech-stack-section .tech-stack-cloud .tech-item.react-js .tech-icon {
  background: #53C1DE;
}
.tech-stack-section .tech-stack-cloud .tech-item.react-native .tech-icon {
  background: #212121;
}
.tech-stack-section .tech-stack-cloud .tech-item.css3 .tech-icon {
  background: #2062AF;
}
.tech-stack-section .tech-stack-cloud .tech-item.html5 .tech-icon {
  background: #E44D26;
}
.tech-stack-section .tech-stack-cloud .tech-item.aws .tech-icon {
  background: #F79C0C;
}
.tech-stack-section .tech-stack-cloud .tech-item.web-components .tech-icon {
  background: #F16529;
}
.tech-stack-section .tech-stack-cloud .tech-item.python .tech-icon {
  background: #3671A2;
}
.tech-stack-section .tech-stack-cloud .tech-item.redux .tech-icon {
  background: #764ABC;
}
.tech-stack-section .tech-stack-cloud .tech-item.node-js .tech-icon {
  background: #63B547;
}
.tech-stack-section .tech-stack-cloud .tech-item.lit .tech-icon {
  background: #647BFE;
}
.tech-stack-section .tech-stack-cloud .tech-item.es6 .tech-icon {
  background: #F26522;
}
.tech-stack-section .tech-stack-cloud .tech-item.js .tech-icon {
  background: #555267;
}

.work-page-hero .projects-showcase {
  margin-top: 48px;
}
.work-page-hero .projects-showcase .section-copy:last-of-type {
  margin-top: 88px;
}
.main-section.project-page-details .project-image {
  margin-bottom: 48px;
  position: relative;
}
.main-section.project-page-details .project-image .image-caption {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 12px;
  font-size: 14px;
  background: #111;
  color: #fff;
  font-weight: 600;
}
.main-section.project-page-details .project-image .image-caption .caption-number {
  font-family: 'Courier New', Courier, monospace;
  opacity: 0.64;
}
.main-section.project-page-details .project-navigation .nav-link {
  width: calc(50% - 24px);
  color: rgba(0, 0, 0, 0.64);
  padding: 24px 12px;
  border: 1px solid #DCE1E6;
}
.main-section.project-page-details .project-navigation .nav-link:first-of-type .icon {
  margin-right: 16px;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type {
  text-align: right;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type .icon {
  margin-left: 16px;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type .aligner {
  margin-left: auto;
}
.main-section.project-page-details .project-navigation .nav-link strong {
  font-size: 14px;
  line-height: normal;
  opacity: 0.54;
}
.main-section.project-page-details .project-navigation .nav-link .project-name {
  font-size: 18px;
  color: #000;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.project-page-details .project-navigation .nav-link:hover {
  background: #f9f9f9;
}
.main-section.project-page-details .project-navigation .nav-link:hover .icon {
  color: #FF6943;
}
.main-section.project-page-details .section-content .section-copy.wide-content {
  width: 70%;
  padding-right: 32px;
}
.main-section.project-page-details .section-content .section-side {
  width: calc(30% - 32px);
}
.main-section.project-page-details .section-content .section-side.project-info {
  border-left: 1px solid #DCE1E6;
  padding-left: 32px;
  margin-top: 40px;
}
.main-section.project-page-details .section-content .section-side.project-info strong,
.main-section.project-page-details .section-content .section-side.project-info span {
  display: block;
  line-height: normal;
  font-size: 14px;
}
.main-section.project-page-details .section-content .section-side.project-info strong {
  color: #000;
}
.main-section.project-page-details .section-content .section-side.project-info .info-item {
  margin-bottom: 16px;
}
.main-section.project-page-details .section-content .section-side.project-info .info-item:last-of-type {
  margin-bottom: 0;
}

.career-page-hero .career-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 48px;
  margin-top: 48px;
  border-top: 1px solid #DCE1E6;
}
.career-page-hero .career-item .career-meta {
  width: 200px;
}
.career-page-hero .career-item .career-meta .meta-item {
  margin-bottom: 24px;
  line-height: normal;
}
.career-page-hero .career-item .career-meta .meta-item strong {
  display: block;
  color: #000;
}
.career-page-hero .career-item .career-description {
  width: calc(100% - 248px);
  border-right: 1px solid #DCE1E6;
  padding-right: 48px;
}
.career-page-hero .career-item .career-description h2 {
  margin-bottom: 24px;
}
.career-page-hero .career-item .career-description ul {
  line-height: 24px;
  margin: 0 0 32px 0;
  padding: 0 0 0 18px;
  list-style-position: outside;
}

.contact-page-hero .contact-location {
  font-size: 24px;
  color: #000;
  margin-top: 16px;
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1em solid rgba(0, 0, 0, 0.08);
  border-left: 1em solid #FF6943;
  border-radius: 50%;
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 8px 0;
  font-weight: 700;
  line-height: normal;
  color: #000;
}
h1,
.h1 {
  font-size: 56px;
  line-height: calc(56px + 10px);
}
h2,
.h2 {
  font-size: 28px;
  line-height: calc(28px + 10px);
}
h3,
.h3 {
  font-size: 26px;
  line-height: calc(26px + 10px);
}
h4,
.h4 {
  font-size: 20px;
  line-height: calc(20px + 10px);
}
h5,
.h5 {
  font-size: 18px;
  line-height: calc(18px + 10px);
}
h6,
.h6 {
  font-size: 14px;
  line-height: calc(14px + 10px);
  text-transform: uppercase;
  margin-bottom: 0;
}
p {
  line-height: 24px;
  margin-bottom: 8px;
}
@media (max-width: 768px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: calc(32px + 8px);
  }
  h2,
  .h2 {
    font-size: 24px;
    line-height: calc(24px + 8px);
  }
  h3,
  .h3 {
    font-size: 20px;
    line-height: calc(20px + 8px);
  }
  h4,
  .h4 {
    font-size: 18px;
    line-height: calc(18px + 8px);
  }
}
::selection {
  background: #000;
  color: #fff;
}
a {
  transition: all 120ms ease-in-out;
  text-decoration: none;
}
a.simple-link {
  text-decoration: underline;
}
a.simple-link:hover {
  opacity: 0.87;
  text-decoration: none;
}
.no-scroll {
  overflow: hidden;
}
.project-card {
  position: relative;
  width: 100%;
  background: #000;
  padding-top: 120%;
  overflow: hidden;
  color: #fff;
  transition: all 240ms ease-in-out;
  margin-bottom: 64px;
}
.project-card .project-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 240ms ease-in-out;
}
.project-card:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  z-index: 2;
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.project-card .project-details {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  padding: 32px;
  width: calc(100% - 56px);
}
.project-card .project-details .project-tagline {
  opacity: 0.64;
  margin-bottom: 4px;
  display: block;
}
.project-card .project-details .project-name {
  font-size: 22px;
  line-height: normal;
}
.project-card .button-link {
  position: absolute;
  z-index: 4;
  bottom: 32px;
  right: 32px;
  width: 48px;
  height: 48px;
  transition: all 240ms ease-in-out;
}
.project-card:hover .project-image {
  transform: scale(1.03);
  opacity: 0.54;
}
.project-card:hover .button-link {
  color: #FF6943;
  background: #fff;
}
.button-link {
  transition: all 120ms ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 8px;
  background: #FF6943;
  color: #fff;
  min-height: 32px;
  min-width: 32px;
  font-size: 18px;
  font-weight: 700;
}
.button-link:hover {
  background: #D45332;
}
.button-link:active {
  background: #B1462A;
}
.primary-color {
  color: #FF6943;
}
.typist-wrapper {
  display: inline-flex;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-100 {
  opacity: 1;
}
section {
  width: 100%;
}
img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
strong {
  font-weight: 700;
}
.color-on-dark {
  color: #fff;
}
.normal-weight {
  font-weight: normal;
}
.light-weight {
  font-weight: 300;
}
.align-center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.default-inline-link {
  color: #000;
  font-weight: 600;
  padding: 4px 0;
  border-bottom: 3px solid #FF6943;
}
.default-inline-link:hover {
  border-color: #000;
}
.flexbox {
  display: flex;
  align-items: center;
}
.flexbox.space-between {
  justify-content: space-between;
}
.flexbox.top-align {
  align-items: flex-start;
}
.flexbox.bottom-align {
  align-items: flex-end;
}
.flexbox.flex-wrap {
  flex-wrap: wrap;
}
.flexbox .center-content {
  justify-content: center;
}
.flexbox.flex-column {
  flex-direction: column;
}
.gray-background {
  background: #f4f4f4;
}
.gray-background .main-section {
  background: #f4f4f4 !important;
}
.white-background {
  background: #fff;
}
.white-background .main-section {
  background: #fff !important;
}
.main-section .section-content .section-copy,
.main-section .section-content .section-side {
  width: calc(50% - 32px);
}
.main-section .section-content .section-copy.wide-content,
.main-section .section-content .section-side.wide-content {
  width: 70%;
}
.main-section a.main-link {
  font-size: 18px;
  color: #000;
  padding-bottom: 10px;
  border-bottom: 3px solid #FF6943;
  display: inline-flex;
  line-height: normal;
  margin-top: 24px;
  font-weight: 700;
  position: relative;
}
.main-section a.main-link .icon {
  position: absolute;
  color: #000;
  right: -8px;
  transition: all 120ms ease-in-out;
  opacity: 0;
}
.main-section a.main-link:hover {
  color: #000;
  border-color: #000;
  padding-right: 20px;
}
.main-section a.main-link:hover .icon {
  opacity: 1;
}
.larger-paragraph {
  font-size: 18px;
  line-height: 28px;
}
.block-content {
  display: block;
}
.has-top-border {
  border-top: 1px solid #DCE1E6;
}
.has-bottom-border {
  border-bottom: 1px solid #DCE1E6;
}
.has-left-border {
  border-left: 1px solid #DCE1E6;
}
.has-right-border {
  border-right: 1px solid #DCE1E6;
}
.no-border {
  border: none;
}
.default-padding {
  padding: 164px 0;
}
.small-padding {
  padding: 64px 0;
}
.no-interaction {
  pointer-events: none;
}
.default-margin {
  margin-top: 24px;
  margin-bottom: 24px;
}
.no-margin {
  margin: 0;
}
.mg-b-8 {
  margin-bottom: 8px;
}
.mg-b-16 {
  margin-bottom: 16px;
}
.mg-t-8 {
  margin-top: 8px;
}
.mg-t-16 {
  margin-top: 16px;
}
.default-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
hr {
  border: none;
  height: 1px;
  background: #DCE1E6;
  margin: 48px 0;
}
hr.short-separator {
  width: 50px;
  height: 4px;
}
hr.mg-24 {
  margin: 24px 0;
}
.spacer-8 {
  height: 8px;
}
.spacer-16 {
  height: 16px;
}
.spacer-24 {
  height: 24px;
}
.spacer-32 {
  height: 32px;
}
.spacer-48 {
  height: 48px;
}
.spacer-64 {
  height: 64px;
}
.spacer-128 {
  height: 128;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.inline-hr {
  width: 24px;
  height: 1px;
  background: #FF6943;
  margin-right: 8px;
}
.all-caps {
  text-transform: uppercase;
  letter-spacing: 0.2px;
}
.small-text {
  font-size: 14px;
  line-height: normal;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.64);
  font-family: trade-gothic-next, 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-display: auto;
}
.app-wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 100vh;
}
.app-wrapper.privacy-tos .content-copy ul,
.app-wrapper.privacy-tos .content-copy li {
  line-height: 24px;
}
.app-wrapper.privacy-tos .content-copy p {
  margin-top: 0;
  margin-bottom: 16px;
}
.app-wrapper.privacy-tos .content-copy a {
  text-decoration: underline;
  color: #000;
}
.app-wrapper.privacy-tos .content-copy strong {
  color: #000;
}
.app-wrapper.privacy-tos .content-copy hr {
  margin: 24px 0;
}
img,
iframe {
  max-width: 100%;
  width: 100%;
}
.breadcrumb ol {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}
.breadcrumb ol li a {
  display: flex;
  align-items: center;
  color: #000;
  opacity: 0.48;
}
.breadcrumb ol li a:hover {
  opacity: 0.72;
}
.breadcrumb ol li a .icon {
  margin-left: -4px;
}
.breadcrumb ol li.selected {
  color: #fff;
  cursor: default;
  pointer-events: none;
}
.mobile-nav-drawer * {
  outline: none;
}
.mobile-nav-drawer .header {
  height: 72px;
  font-size: 18px;
  padding: 0 24px;
  display: flex !important;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 1px solid #DCE1E6;
}
.mobile-nav-drawer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.mobile-nav-drawer ul li {
  display: block;
  position: relative;
}
.mobile-nav-drawer ul li a {
  display: block;
  padding: 24px;
  font-size: 18px;
  line-height: normal;
  color: #000;
}
.mobile-nav-drawer ul li a:hover {
  background: #f4f4f4;
}
.mobile-nav-drawer ul li.selected {
  font-weight: 600;
}
.mobile-nav-drawer ul li.selected a {
  color: #FF6943;
  background: #f4f4f4;
}
.mobile-nav-drawer ul li.selected a:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background: #FF6943;
}
.bm-cross-button {
  width: 40px !important;
  height: 40px !important;
  top: 14px !important;
  right: 8px !important;
}
.bm-cross-button .bm-cross {
  color: #000;
  font-size: 24px;
  opacity: 0.54;
}
.bm-cross-button svg {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -12px 0 0 -12px;
}
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out 0s !important;
  -webkit-transition: all 0.3s ease-in-out 0s !important;
}
.bm-menu {
  background: #fff;
}
.bm-morph-shape {
  fill: #fff;
}
.bm-item-list {
  background: #fff;
}
.bm-burger-button {
  display: none;
  color: #fff !important;
  fill: #fff !important;
}
.bm-burger-button svg {
  fill: #fff !important;
}
.app-header {
  flex: 0 0 auto;
  padding: 0 24px;
  height: 92px;
  border-bottom: 1px solid #DCE1E6;
}
.app-header .mobile-navigation {
  display: none;
  position: relative;
}
.app-header .mobile-navigation .mobile-nav-links {
  position: absolute;
}
.app-header .mobile-navigation .navigation-trigger {
  height: 72px;
  padding-bottom: 4px;
  cursor: pointer;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
  color: #000;
  font-size: 18px;
}
.app-header .mobile-navigation .navigation-trigger .icon {
  margin-right: 8px;
  opacity: 0.64;
  font-size: 24px;
}
.app-header .mobile-navigation .navigation-trigger strong {
  margin-top: -2px;
  line-height: normal;
  font-weight: normal;
}
.app-header .mobile-navigation .navigation-trigger:active {
  opacity: 0.54;
}
.app-header .logo {
  height: 48px;
  width: 129px;
  background: url(/static/media/logo.2762163f.svg) no-repeat center left;
  background-size: 100%;
  display: inline-flex;
}
.app-header .desktop-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 18px;
}
.app-header .desktop-navigation li {
  margin-left: 40px;
  margin-top: -8px;
}
.app-header .desktop-navigation li:first-of-type {
  margin-left: 0;
}
.app-header .desktop-navigation li a {
  color: rgba(0, 0, 0, 0.54);
  padding: 12px 0;
  position: relative;
}
.app-header .desktop-navigation li a:hover {
  color: #000;
}
.app-header .desktop-navigation li.selected a {
  color: #FF6943;
}
.app-header .desktop-navigation li.selected a:after {
  content: '';
  height: 4px;
  width: 100%;
  background: #FF6943;
  position: absolute;
  bottom: -27px;
  left: 0;
  right: 0;
}
.app-header.transparent {
  background: transparent;
  position: relative;
  z-index: 2;
  border-color: transparent;
}
.app-header.transparent .logo {
  background: url(/static/media/logo-negative.8accaa9a.svg) no-repeat center left;
  background-size: 100%;
}
.app-header.transparent .desktop-navigation li a {
  color: rgba(255, 255, 255, 0.54);
  padding: 12px 0;
  border-bottom: 4px solid transparent;
}
.app-header.transparent .desktop-navigation li a:hover {
  color: #fff;
}
.app-header.transparent .desktop-navigation li.selected a {
  color: #FF6943;
  border-color: #FF6943;
  cursor: default;
}
.app-header.transparent .mobile-navigation .navigation-trigger {
  color: #fff;
}
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 999 !important;
  -webkit-transition: background 200ms ease-in-out;
  transition: background 200ms ease-in-out;
}
.headroom-wrapper {
  height: 92px !important;
}
.headroom-wrapper.has-big-hero .headroom--pinned {
  border-bottom: 1px solid #DCE1E6;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .logo {
  height: 48px;
  width: 129px;
  background: url(/static/media/logo.2762163f.svg) no-repeat center left;
  background-size: 100%;
  display: inline-flex;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li a {
  color: rgba(0, 0, 0, 0.54);
  border-bottom: 4px solid transparent;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li a:hover {
  color: #000;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .desktop-navigation li.selected a {
  color: #FF6943;
  border-color: #FF6943;
}
.headroom-wrapper.has-big-hero .headroom--pinned .app-header .mobile-navigation .navigation-trigger {
  color: #000;
}
.headroom--unfixed {
  position: relative;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.headroom--scrolled {
  -webkit-transition: -webkit-transform 200ms ease-in-out;
  transition: -webkit-transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out;
  transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  border-bottom: 1px solid #DCE1E6;
}
.headroom--pinned {
  position: fixed;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  background: #ffffff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);
}
.app-content {
  flex: 1 1;
  -webkit-animation: fade-in 500ms ease-in-out;
  animation: fade-in 500ms ease-in-out;
  min-height: 0;
}
.content-wrapper {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;
  z-index: 3;
}
@media (max-height: 940px) {
  .default-padding {
    padding: 100px 0;
  }
  .app-content.has-big-hero .big-hero {
    min-height: 100vh;
  }
}
@media (min-width: 1104px) {
  .app-header {
    padding: 0 40px;
  }
}
@media (max-width: 1104px) {
  .default-padding {
    padding: 56px 0;
  }
  .main-section .section-content {
    flex-wrap: wrap;
  }
  .main-section .section-content .section-copy,
  .main-section .section-content .section-side {
    width: calc(50% - 16px);
  }
}
@media (max-width: 800px) {
  .default-padding,
  .small-padding {
    padding: 48px 0;
  }
  hr {
    margin: 24px 0;
  }
  .app-content.has-big-hero .big-hero {
    min-height: unset;
    margin-top: -72px;
    padding: 128px 0 64px 0;
  }
  .app-content.has-big-hero .big-hero h1 {
    font-size: 56px;
    line-height: 66px;
    max-width: unset;
  }
  .app-content.has-big-hero .big-hero a.button-link {
    width: 64px;
    height: 64px;
    margin-top: 48px;
  }
  .main-section .section-content {
    flex-wrap: wrap;
  }
  .main-section .section-content .section-copy {
    width: 100%;
  }
  .main-section .section-content .section-copy.wide-content {
    width: 100%;
  }
  .main-section .section-content .section-side {
    width: 100%;
  }
  .main-section a.main-link {
    margin-top: 16px;
    padding-right: 20px;
  }
  .main-section a.main-link .icon {
    opacity: 1;
    top: 2px;
  }
  .main-section.who-we-are .team-image {
    margin-top: 16px;
  }
  .main-section.work-samples .section-content {
    flex-wrap: wrap-reverse;
  }
  .main-section.what-we-do .services-grid {
    border: 1px solid #DCE1E6;
    border-bottom: none;
    border-left: none;
    border-radius: 4px;
    overflow: hidden;
  }
  .main-section.what-we-do .services-grid li {
    border-left: 1px solid #DCE1E6;
    border-bottom: 1px solid #DCE1E6;
  }
  .main-section.about-page-hero .about-key-points .key-item {
    width: calc(50% - 12px);
    margin-bottom: 24px;
  }
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(3),
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(4) {
    margin-bottom: 0;
  }
  .main-section.about-page-team .team-listing .team-member {
    width: calc(50% - 12px);
    margin-top: 24px;
  }
  .main-section.services-page-process .process-listing {
    padding-top: 24px;
    margin-top: 24px;
  }
  .main-section.services-page-process .process-listing .process-item {
    width: calc(50% - 12px);
    margin-bottom: 32px;
  }
  .main-section.services-page-process .process-listing .process-item:last-of-type {
    margin-bottom: 0;
  }
  .main-section.services-page-hero .service-list {
    margin-top: 16px;
    justify-content: flex-start;
    border-bottom: none;
    border-right: none;
  }
  .main-section.services-page-hero .service-list .service-item {
    width: calc(100% / 2);
  }
  .main-section.services-page-hero .service-list .service-item a {
    padding: 24px 16px;
    border: 1px solid #DCE1E6;
  }
  .main-section.services-page-hero .service-list .service-item a .service-icon {
    font-size: 48px;
    margin: 0;
  }
  .main-section.services-page-hero .service-list .service-item a h2 {
    margin-bottom: 0;
  }
  .main-section.services-page-hero .service-list .service-item a:hover {
    box-shadow: none;
  }
  .main-section.service-detail .section-content {
    flex-wrap: wrap-reverse;
  }
  .main-section.service-detail .service-image {
    margin-bottom: 24px;
    max-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -48px;
  }
  .main-section.service-detail .service-image .section-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    bottom: 0;
    top: auto;
    right: auto;
    left: 24px;
  }
  .main-section.tech-stack-section .tech-stack-cloud {
    margin-top: 16px;
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    border-radius: 16px;
  }
  .main-section.work-page-hero .projects-showcase {
    margin-top: 16px;
  }
  .main-section.project-page-details .project-navigation .nav-link {
    width: 50%;
    padding: 16px 12px;
  }
  .main-section.project-page-details .project-navigation .nav-link:first-of-type {
    border-right: none;
  }
  .main-section.project-page-details .project-navigation .nav-link .aligner {
    width: calc(100% - 40px);
  }
  .main-section.project-page-details .project-navigation .nav-link .project-name,
  .main-section.project-page-details .project-navigation .nav-link strong {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
  .main-section.project-page-details .section-content .section-copy.wide-content {
    width: 100%;
    padding: 0;
  }
  .main-section.project-page-details .section-content .section-side.project-info {
    padding: 16px;
    border-radius: 4px;
    margin-top: 16px;
    border: 1px solid #DCE1E6;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item {
    border-bottom: 1px solid #DCE1E6;
    padding-bottom: 16px;
    width: 100%;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item strong {
    margin-bottom: 2px;
  }
  .main-section.project-page-details .section-content .section-side.project-info .info-item:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
  .main-section.project-page-details .spacer-64 {
    height: 24px;
  }
  .main-section.project-page-details .project-image {
    margin-bottom: 24px;
  }
  .main-section.project-page-details .project-image:last-of-type {
    margin-bottom: 0;
  }
  .main-section.career-page-hero .career-item {
    padding-top: 16px;
    margin-top: 16px;
    flex-wrap: wrap-reverse;
  }
  .main-section.career-page-hero .career-item .career-description,
  .main-section.career-page-hero .career-item .career-meta {
    width: 100%;
    padding: 0;
    border: none;
  }
  .main-section.career-page-hero .career-item .career-meta {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #DCE1E6;
    margin-bottom: 16px;
  }
  .main-section.career-page-hero .career-item .career-meta .meta-item {
    width: 48%;
  }
  .main-section.contact-page-hero .spacer-48 {
    height: 24px;
  }
  .main-section.contact-page-hero .section-copy {
    margin-bottom: 24px;
  }
  .main-section.contact-page-hero .section-copy:last-of-type {
    margin-bottom: 0;
  }
  .main-section.contact-page-hero .section-copy p {
    margin-top: 0;
  }
  .project-card {
    margin-bottom: 24px;
  }
  .app-header {
    padding-top: 0;
    height: 72px;
  }
  .app-header .logo {
    height: 40px;
    width: 108px;
  }
  .app-header .desktop-navigation {
    font-size: 15px;
    line-height: normal;
    font-weight: 600;
  }
  .app-header .desktop-navigation li {
    margin-left: 24px;
  }
  .app-header .desktop-navigation li a {
    padding: 16px 0;
  }
  .app-header .desktop-navigation li a:after {
    bottom: -15px;
  }
  .app-header .desktop-navigation li.selected a:after {
    bottom: -15px;
  }
  .app-footer .general-info {
    margin: 48px 0;
  }
  .app-footer .general-info .item {
    margin-right: 48px;
  }
  .app-footer .social-copy {
    flex-wrap: wrap;
  }
  .app-footer .social-copy .social-links {
    width: 100%;
  }
  .app-footer .social-copy .copyright {
    margin-top: 24px;
  }
  .headroom-wrapper {
    height: 72px !important;
  }
  .headroom-wrapper.has-big-hero .headroom--pinned .app-header .logo {
    height: 40px;
    width: 108px;
  }
}
@media (max-width: 548px) {
  .app-header {
    padding-top: 0;
    height: 72px;
  }
  .app-header .logo {
    height: 40px;
    width: 108px;
  }
  .app-header .desktop-navigation {
    display: none;
  }
  .app-header .mobile-navigation {
    display: block;
  }
}
@media (max-width: 500px) {
  .main-section.what-we-do .services-grid li {
    width: 100%;
  }
  .main-section.about-page-hero .about-key-points .key-item {
    width: 100%;
    margin-bottom: 24px;
  }
  .main-section.about-page-hero .about-key-points .key-item:nth-of-type(3) {
    margin-bottom: 24px;
  }
  .main-section.about-page-team .team-listing .team-member {
    width: 100%;
  }
  .main-section.services-page-process .process-listing .process-item {
    width: 100%;
    margin-bottom: 24px;
  }
  .main-section.services-page-process .process-listing .process-item:last-of-type {
    margin-bottom: 0;
  }
  .main-section.project-page-details .project-navigation {
    flex-wrap: wrap;
  }
  .main-section.project-page-details .project-navigation .nav-link {
    width: 100%;
  }
  .main-section.project-page-details .project-navigation .nav-link:first-of-type {
    border-right: 1px solid #DCE1E6;
    margin-bottom: 8px;
  }
  .main-section.project-page-details .project-navigation .nav-link .aligner {
    width: calc(100% - 40px);
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item {
    width: calc(100% / 3);
    padding: 16px 8px;
  }
  .main-section.tech-stack-section .tech-stack-cloud .tech-item .tech-icon {
    width: 64px;
    height: 64px;
    font-size: 32px;
    border-radius: 16px;
  }
  .app-footer .general-info {
    margin: 48px 0 0 0;
    flex-wrap: wrap;
  }
  .app-footer .general-info .item {
    margin-right: 0;
    margin-bottom: 48px;
    width: 100%;
  }
  .app-content.has-big-hero .big-hero h1 {
    font-size: 32px;
    line-height: 42px;
    font-weight: 400;
  }
  .app-content.has-big-hero .big-hero a.button-link {
    margin-top: 24px;
  }
}

