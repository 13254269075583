.app-content.has-big-hero .big-hero {
  position: relative;
  margin-top: -92px;
  z-index: 1;
  background-color: #000;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 128px 0;
}
.app-content.has-big-hero .big-hero:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: url(../../assets/images/hero-image.jpg) no-repeat center;
  background-size: cover;
  opacity: 0.16;
}
.app-content.has-big-hero .big-hero h1 {
  font-size: 72px;
  line-height: 82px;
  font-weight: 300;
  color: #fff;
  max-width: 80%;
  margin: 0;
}
.app-content.has-big-hero .big-hero a.button-link {
  width: 86px;
  height: 86px;
  margin-top: 64px;
}
.app-content.has-big-hero .big-hero a.button-link .icon {
  font-size: 32px;
}
