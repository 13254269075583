.work-page-hero .projects-showcase {
  margin-top: 48px;
}
.work-page-hero .projects-showcase .section-copy:last-of-type {
  margin-top: 88px;
}
.main-section.project-page-details .project-image {
  margin-bottom: 48px;
  position: relative;
}
.main-section.project-page-details .project-image .image-caption {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 12px;
  font-size: 14px;
  background: #111;
  color: #fff;
  font-weight: 600;
}
.main-section.project-page-details .project-image .image-caption .caption-number {
  font-family: 'Courier New', Courier, monospace;
  opacity: 0.64;
}
.main-section.project-page-details .project-navigation .nav-link {
  width: calc(50% - 24px);
  color: rgba(0, 0, 0, 0.64);
  padding: 24px 12px;
  border: 1px solid #DCE1E6;
}
.main-section.project-page-details .project-navigation .nav-link:first-of-type .icon {
  margin-right: 16px;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type {
  text-align: right;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type .icon {
  margin-left: 16px;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.project-page-details .project-navigation .nav-link:last-of-type .aligner {
  margin-left: auto;
}
.main-section.project-page-details .project-navigation .nav-link strong {
  font-size: 14px;
  line-height: normal;
  opacity: 0.54;
}
.main-section.project-page-details .project-navigation .nav-link .project-name {
  font-size: 18px;
  color: #000;
  -webkit-transition: all 120ms ease-in-out;
  transition: all 120ms ease-in-out;
}
.main-section.project-page-details .project-navigation .nav-link:hover {
  background: #f9f9f9;
}
.main-section.project-page-details .project-navigation .nav-link:hover .icon {
  color: #FF6943;
}
.main-section.project-page-details .section-content .section-copy.wide-content {
  width: 70%;
  padding-right: 32px;
}
.main-section.project-page-details .section-content .section-side {
  width: calc(30% - 32px);
}
.main-section.project-page-details .section-content .section-side.project-info {
  border-left: 1px solid #DCE1E6;
  padding-left: 32px;
  margin-top: 40px;
}
.main-section.project-page-details .section-content .section-side.project-info strong,
.main-section.project-page-details .section-content .section-side.project-info span {
  display: block;
  line-height: normal;
  font-size: 14px;
}
.main-section.project-page-details .section-content .section-side.project-info strong {
  color: #000;
}
.main-section.project-page-details .section-content .section-side.project-info .info-item {
  margin-bottom: 16px;
}
.main-section.project-page-details .section-content .section-side.project-info .info-item:last-of-type {
  margin-bottom: 0;
}
