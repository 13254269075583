.app-footer {
  flex: 0 0 auto;
  color: #fff;
  background: #111;
}
.app-footer h1,
.app-footer h5 {
  color: #fff;
}
.app-footer .general-info {
  margin: 64px 0 92px 0;
  display: flex;
  align-items: center;
}
.app-footer .general-info .item {
  margin-right: 100px;
}
.app-footer .general-info .item:last-of-type {
  margin-right: 0;
}
.app-footer .general-info a {
  color: #fff;
  padding-bottom: 8px;
  border-bottom: 2px solid #fff;
  opacity: 0.54;
}
.app-footer .general-info a:hover {
  opacity: 0.87;
}
.app-footer .social-copy .social-links a {
  color: #fff;
  display: inline-flex;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  font-size: 32px;
  line-height: 32px;
}
.app-footer .social-copy .social-links a:first-of-type {
  margin-left: -12px;
}
.app-footer .social-copy .social-links a .icon {
  font-size: 24px;
}
.app-footer .social-copy .social-links a:last-of-type {
  margin-right: 0;
}
.app-footer .social-copy .social-links a:hover {
  opacity: 0.54;
}
.app-footer .social-copy .copyright {
  font-size: 14px;
  line-height: normal;
  opacity: 0.48;
}
.app-footer .social-copy .copyright a {
  color: #fff;
  margin-right: 24px;
}
