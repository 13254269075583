.about-page-hero .about-key-points {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-page-hero .about-key-points .key-item {
  width: calc(50% - 32px);
  margin-bottom: 56px;
}
.about-page-hero .about-key-points .key-item .key-icon {
  font-size: 64px;
  color: #FF6943;
  margin-bottom: 16px;
}
.about-page-hero .about-key-points .key-item .key-name {
  font-size: 24px;
  color: #000;
}
.about-page-team .team-listing {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.about-page-team .team-listing .team-member {
  margin-top: 48px;
  width: calc(50% - 32px);
}
.about-page-team .team-listing .team-member img {
  margin-bottom: 8px;
}
.about-page-team .team-listing .team-member h2 {
  font-weight: normal;
  font-size: 24px;
  line-height: normal;
}
.about-page-team .team-listing .team-member .image-wrapper {
  position: relative;
}
.about-page-team .team-listing .team-member .image-wrapper .external-url {
  position: absolute;
  bottom: 30px;
  right: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FF6943;
  color: #fff;
  font-size: 20px;
}
.about-page-team .team-listing .team-member .image-wrapper .external-url:hover {
  background: #000;
}
