@font-face {
  font-family: 'eggnita';
  src: url('../../assets/icon-font/eggnita.woff?fot4jn') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: auto;
}
[class^="custom-icon-"],
[class*=" custom-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'eggnita' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-icon-adapt:before {
  content: "\e91b";
}
.custom-icon-goal:before {
  content: "\e91c";
}
.custom-icon-time:before {
  content: "\e91d";
}
.custom-icon-values:before {
  content: "\e91e";
}
.custom-icon-back-end:before {
  content: "\e900";
}
.custom-icon-behance:before {
  content: "\e901";
}
.custom-icon-cloud-architecture:before {
  content: "\e902";
}
.custom-icon-consultancy:before {
  content: "\e903";
}
.custom-icon-copywriting:before {
  content: "\e904";
}
.custom-icon-dribbble:before {
  content: "\e905";
}
.custom-icon-facebook:before {
  content: "\e906";
}
.custom-icon-linked-in:before {
  content: "\e907";
}
.custom-icon-medium:before {
  content: "\e908";
}
.custom-icon-mobile-apps:before {
  content: "\e909";
}
.custom-icon-seo:before {
  content: "\e90a";
}
.custom-icon-social-media:before {
  content: "\e90b";
}
.custom-icon-twitter:before {
  content: "\e90c";
}
.custom-icon-ui-ux:before {
  content: "\e90d";
}
.custom-icon-web-apps:before {
  content: "\e90e";
}
.custom-icon-web-services:before {
  content: "\e90f";
}
.custom-icon-aws:before {
  content: "\e910";
}
.custom-icon-css:before {
  content: "\e911";
}
.custom-icon-es6:before {
  content: "\e912";
}
.custom-icon-html:before {
  content: "\e913";
}
.custom-icon-js:before {
  content: "\e914";
}
.custom-icon-lit:before {
  content: "\e915";
}
.custom-icon-node:before {
  content: "\e916";
}
.custom-icon-python:before {
  content: "\e917";
}
.custom-icon-react:before {
  content: "\e918";
}
.custom-icon-redux:before {
  content: "\e919";
}
.custom-icon-web-components:before {
  content: "\e91a";
}
